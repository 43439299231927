@import "../src/global.scss";

.app{
    height: 100vh;

    .menu{
        height: 30%;
        top: 70px;
        margin-right: 10px;
    }

    .section{
        width: 100%;
        height: calc(100vh - 70px);
        color: black;
        position: relative;
        top: 70px;
        scroll-behavior:smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }

        @include mobile{
            scroll-snap-type: none;
        }

        >* {
            width: 100vw;
            height: calc(100vh - 70px);
            scroll-snap-align: start;
        }
    }
}