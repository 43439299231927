@import "../../global.scss";

.contact{
    background-color: white;
    display: flex;
    flex-direction: column;

    .top{
        flex: 0.7;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include mobile{
            h2{
                margin-bottom: 20px;
            }
        }

        h2{
            font-size: 220%;
            text-decoration: underline lightgray;
        }

        form{
            //border: solid;
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            div{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                input{
                    width: 30%;
                    height: 20px;
                    font-size: 110%;
    
                    @include mobile{
                        width: 90%;
                    }
                }
    
                textarea{
                    width: 50%;
                    height: 90px;
                    resize: none;
    
                    @include mobile{
                        width: 100%;
                    }
                }
    
                button{
                    width: 10%;
                    height: 35px;
                    color: black;
                    border: none;
                    border-radius: 8%;
                    font-weight: 500;
                    cursor: pointer;
    
                    @include mobile{
                        width: 50%;
                    }
    
                    &:focus{
                        outline: none;
                    }
                }
            }
        }
    }

    .bottom{
        background-color:$mainBackgroundColor;
        flex: 0.3;
        border-top: solid black;
        display: flex;
        flex-direction: column;
        padding-bottom: 2%;
        justify-content: space-around;
        text-align: center;
        
        .arrow{
            font-size: 10vh;
            color: black;
            animation: arrowBlink 3s infinite;
        }

        @keyframes arrowBlink {
            100%{
                opacity: 0;
            }
        }

        .item{
            padding-bottom: 1vh;
            margin-left: 1%;
            margin-right: 1%;
            font-size: 230%;
            color: black;
        }

        p{
            color: rgba(0, 0, 0, 0.24);
        }

    }

    #button:hover{
        width: 10%;
        height: 35px;
        color: white;
        background-color: black;
        border: none;
        border-radius: 8%;
        font-weight: 500;
        cursor: pointer;

        @include mobile{
            width: 50%;
        }
    }
}