@import "../../global.scss";

.about{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-evenly;
    background-color: lightgray;

    h2{
        //border: solid;
        font-size: 220%;
        flex: 0.2;
        flex-direction: column;
        display: flex;
        justify-content: center;

        @include mobile{
            //border: solid red;
            flex: 0.1;
            font-size: 220%;
            
            h2{
                bottom: 1px;
            }
        }
    }

    .bottom{
        //border: solid;
        width: 45%;
        flex: 1;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: space-around;

        @include mobile{
            margin-top: 10px;
            flex: 0.9;
            width: 70%;
        }

        p{
            overflow: hidden;
            font-size: 140%;
            text-align: center;

            @include mobile{
                font-size: 2.4vh;
            }

        }

        span{
            text-align: center;

            a{
                font-size: 200%;
                color: black;
                text-decoration: none;

                @include mobile{
                    font-size: 3.5vh;
                }
            }
        }
    }
    
    #location:hover{
        font-size: 200%;
        color: white;
        text-decoration: none;
    }
}