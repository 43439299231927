@import "../../global.scss";


.intro{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }

    .introBackground{
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .top{
        flex: 0.4;
        height: inherit;
        //border: solid blue;
        display: flex;
        justify-content: center;
        overflow: hidden;

        .imgContainer{
            //border: solid white;
            width: 95%;
            height: 94%;
            margin-top: 10px;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            @include mobile{
                width: 100%;
            }


            img{
                background-color:inherit;
                border-radius: 40px 40px 40px 40px;
                width: 220px;
                height: 250px;
            }
        }
    }

    .bottom{
        //border: solid red;
        display: flex;
        flex-direction: column;
        flex: 0.6;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;

        h1{
            overflow: hidden;
            text-align: center;
            color: white;        
            font-size: 400%;
            position: relative;

            @include mobile{
                font-size: 300%;
            }

        }
        
        .wrapper{
            //border: solid blue;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            color: white;
            overflow: hidden;

            h2{
                position: relative;
                font-size: 180%;
                //margin-top: 1%;
                overflow: hidden;

                @include mobile{
                    font-size: 120%;
                }
    

                .ityped-cursor{
                    animation: blink 1s infinite;
                }

                @keyframes blink {
                    50%{
                        opacity: 1;
                    }
                    100%{
                        opacity: 0;
                    }
                }
            }

            .itemContainer{
                margin-top: 4.5%;
                overflow: hidden;
            

                .item{
                    color: white;
                    font-size: 250%;
                    padding-left: 15px;
                    padding-right: 15px;
                    overflow: hidden;

                    @include mobile{
                        height: 100%;
                    }
        
                    
                }

            }

            .arrow{
                font-size: 500%;
                justify-content: center;
                color: white;
                animation: arrowBlink 3s infinite;
                overflow: hidden;

                @include mobile{
                    height: 90%;
                }
    
            }

            @keyframes arrowBlink {
                100%{
                    opacity: 0;
                }
            }
        }
    }
}
