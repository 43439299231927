@import "../../global.scss";

.topbar{
    width: 100%;
    height: 70px;
    background-color:black;
    color:$mainFontColor;
    position: fixed;
    z-index: 3;

    .wrapper{
        //padding: 10px 30px;
        display: flex;
        height: inherit;
        width: inherit;

        .left{
            //border: solid white;
            flex: 0.3;
            display: flex;
            align-items: center;
            padding-left: 10px;
            //bottom: 0;
            //padding-top: 5px;

            .logo{
                display: flex;
                justify-content: center;
                align-items: center;

                img{
                    width: 50px;
                    overflow: hidden;
                }
            }

        }

        .right{
            //border: solid red;
            flex: 0.7;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 15px;

            a{
                font-size: 3vh;
                font-weight: 400;
                text-decoration: none;
                color:white;
                padding-left: 20px;
                padding-right: 0px;

            }

            .hamburguer{
                //border: solid white;
                width: 40px;
                height: 30px;
                display: none;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                overflow: hidden;

                span{
                    width: 100%;
                    height: 3px;
                    background-color: $mainBackgroundColor;
                    transform-origin: left;
                    transition: all 2s ease;
                }
            }

            @include mobile{

                a{
                    display: none;
                }
                .hamburguer{
                    //border: solid white;
                    width: 40px;
                    height: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    cursor: pointer;
                    overflow: hidden;
    
                    span{
                        width: 100%;
                        height: 3px;
                        background-color: $mainBackgroundColor;
                        transform-origin: left;
                        transition: all 1s ease;
                    }
                }
            }
        }
    }

    &.active{

        .hamburguer{
            span{
                &:first-child{
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:nth-child(3){
                    transform: rotate(-45deg);

                }
            }
        }
    }

    // #coolLogo::after {
    //     content: "";
    //     display: block;
    //     width: 0;
    //     height: 2px;
    //     background-color: #fff;
    // }

    // #coolLogo:hover::after{
    //     width: 210px;
    //     transition: width .3s;
    // }
}

