@import "../../global.scss";

.projects{
    background-color: $mainBackgroundColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    h2{
        margin-top: 2%;
        font-size: 220%;
    }

    .container{
        //border: solid;
        width: 70%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        
        .item{
            width: 200px;
            height: 230px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 10px 30px;
            position: relative;
            
            a{
                width: 100%;
                height: 80%;
                position: absolute;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color:black;
                text-decoration: none;

                p{
                    width: 100%;
                }

            }

            img{
                background-color: $mainBackgroundColor;
                z-index: 1;
            }

            &:hover{
                background-color: $mainBackgroundColor;
                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
    }
}